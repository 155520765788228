<template>
<div>
  <div class="body_bg">
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        :color="snackbarColor"
        absolute
        top
        v-model="snackbarShow"
    >{{snackbarText}}
    </v-snackbar>
    <div class="container">
      <div class="left">
        <img src="~assets/img/home/login_01.png">
      </div>
      <div class="right">
        <div class="r_title">
          <v-icon large color="#009688">mdi-home-city-outline</v-icon>
          <div class="card_title">注册新用户</div>
        </div>
        <div class="r_input">
          <!--用户名-->
          <v-text-field
              v-model="signUpMsg.username"
              label="用户名(账号)"
              placeholder="请输入..."
              filled
              rounded
              dense
              counter="15"
              maxlength="15"
              :rules="[v => !!v || '不能为空！']"
          ></v-text-field>
          <!--密码-->
          <v-text-field
              v-model="signUpMsg.password"
              label="密码"
              placeholder="请输入..."
              counter="25"
              maxlength="25"
              filled
              rounded
              dense
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :rules="[v => v.length >= 6 || '密码最短长度为6']"
          ></v-text-field>
        </div>
        <div class="r_bt text-center">
          <v-btn
              depressed
              color="#97D1B6"
              rounded
              width="88%"
              height="40px"
              dark
              style="font-weight: bold;font-size: 16px"
              @click="signUp"
          >注册</v-btn>
        </div>
        <div class="r_registered">
          注册即表示您同意我们的
          <span style="color:#55a532" >服务条款与隐私政策</span>
        </div>
        <div class="r_registered">
          已经拥有账户？
          <span style="color:#55a532" @click="toLogin">登录</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {userSignUp,verifyUsername} from "../../network/Details/home";

export default {

  name: "SignUp",
  data(){
    return{
      //密码显示
      show:false,
      //账号
      signUpMsg:{
        username:'',
        password:'',
      },
      //提示
      snackbarShow:false,
      snackbarText:'',
      snackbarColor:'#F44336',
    }
  },
  methods:{
    //注册
    signUp(){
      if(this.signUpMsg.username.length == 0 || this.signUpMsg.password.length == 0){
        this.snackbarText = '用户名或密码不能为空！';
        this.snackbarShow = true;
        return
      }
      if( this.signUpMsg.password.length < 6){
        this.snackbarText = '密码最短长度为6';
        this.snackbarShow = true;
        return
      }
      verifyUsername({
        username:this.signUpMsg.username
      }).then(res => {
        if(res.code === 500){
          this.snackbarText = res.message;
          this.snackbarShow = true;
        }else {
          userSignUp(this.signUpMsg).then(res=>{
            if(res.code === 200){
              this.snackbarColor = '#4CAF50';
            }
            this.snackbarText = res.message;
            this.snackbarShow = true;
          })
          this.snackbarText = '注册成功！';
          this.snackbarColor = '#4CAF50';
          this.snackbarShow = true;
        }
      });
    },
    //跳转到注册
    toLogin(){
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.body_bg{
  position:absolute;
  left:0;
  top:0px;
  width:100%;
  height:100%;
  background-image: linear-gradient(-225deg, #20E2D7 0%, #F9FEA5 100%);
}
.container{
  width: 691px;
  height: 411px;
  border-radius: 21px;
  background: #ffffff;
  box-shadow: 3px 2px 8px rgba(0,0,0,0.31);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 15px;
  display: flex;
  flex-direction: row;
  .left{
  }
  .right{
    height: 365px;
    width: 340px;
    border: #F6F6F6 1px solid;
    display: flex;
    flex-direction: column;
    .r_title{
      height: 60px;
      display: flex;
      flex-direction: row;
      font-size: 18px;
      text-align: center;
      line-height: 60px;
      font-weight: bold;
      justify-content: space-evenly;
    }
    .r_input{
      margin-top: 20px;
      width: 90%;
      margin-left: 6%;
    }
    .r_bt{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .r_registered{
      text-align: center;
      color: #666666;
      font-size: 13px;
      margin-bottom: 5px;
    }

  }
}
</style>