import {request} from "../request";
//登录
export function userLogin(userDate){
  return request({
    url:'login',
    method:'post',
    params:userDate
  })
}
//注册
export function userSignUp(userDate){
  return request({
    url:'user/register',
    method:'post',
    params:userDate
  })
}
//检查账号唯一性
export function verifyUsername(userDate){
  return request({
    url:'user/checkUsernameUnique',
    method:'get',
    params:userDate
  })
}
